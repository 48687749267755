import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const FloatingBoxContext = createContext();

export const FloatingBoxProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);

  const showFloatingBox = () => setIsVisible(true);
  const hideFloatingBox = () => setIsVisible(false);

  return (
    <FloatingBoxContext.Provider
      value={{ isVisible, showFloatingBox, hideFloatingBox }}
    >
      {children}
    </FloatingBoxContext.Provider>
  );
};
export const useFloatingBox = () => useContext(FloatingBoxContext);

FloatingBoxProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
